
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "vue";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";
import Toastify from "toastify-js";
import useWiseFetch from "@/composables/useWiseFetch";
import { getPackage } from "@/services/v1/public";
import { BaseResponse } from "@/services/types";
import { ResponseGetPackage } from "@/services/v1/public/types";
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { setBreadcrumb } from "@/utils/breadcrumb";

const PackageCard = defineAsyncComponent(
  () => import(`./components/package-card.vue`)
);

export default defineComponent({
  components: {
    PackageCard,
  },
  setup() {
    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Upgrade Paket",
        to: `${dashboardPath}/upgrade-paket`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    const ld: any = api.getLoginData();
    const paketNow = ld.paket;
    const titleTable: Array<string> = [
      "Item",
      "Price",
      "Discount",
      "SubTotals",
    ];

    const dataUA = reactive({
      data: [
        {
          id: 1,
          title: "",
          price: 0,
        },
        {
          id: 2,
          title: "",
          price: 0,
        },
      ] as any,
      pricePremium: {
        price: "0",
        newPrice: "0",
      },
      pricePlatinum: {
        price: "0",
        newPrice: "0",
      },
    });

    const {
      data: dataPackage,
      pending: pendingPackage,
      error: errorPackage,
      execute: executePackage,
    } = useWiseFetch<BaseResponse<ResponseGetPackage[]>>(getPackage);

    const prettierDataPackage = computed(() => {
      const response = dataPackage.value?.response;
      return response?.reverse();
    });

    watch(
      () => prettierDataPackage.value,
      (newVal) => {
        dataUA.data = newVal;
        console.log("dataPackage ", newVal);
      }
    );

    onMounted(async () => {
      gpf.gLoading.show();
      await executePackage();
      gpf.gLoading.hide();
    });

    return {
      titleTable,
      dataUA,
      paketNow,
      gpf,
      prettierDataPackage,
    };
  },
  data() {
    return {
      exDataUA: {
        packageId: "",
        paymentId: "",
        promoId: "",
        kode: "",
        discount: false,
        priceDiscount: "0",
      },
      listPayment: [],
      dataPaket: {
        id: 1,
        title: "",
        price: 0,
      } as any,
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataUA;
      ex[f] = val;
    },
    async pilihPaket(idx: number) {
      gpf.gLoading.show();
      const ex = this.exDataUA;
      const hitApi = await api.getPayment();
      this.listPayment = hitApi;
      ex.paymentId = hitApi[0].options[0].value;
      ex.packageId = this.dataUA.data[idx].id;

      this.dataPaket = this.dataUA.data[idx];
      this.dataPaket.idx = idx;

      gpf.showModal("#modal-pilih-paket");
      gpf.gLoading.hide();
    },
    async validasiPromo() {
      gpf.gLoading.show();

      const ex = this.exDataUA;
      const idx = this.dataPaket.idx;

      const hide = () => gpf.gLoading.hide();
      const url = api.publicPromo;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      const hitApi = await api.hitApiPost(url, params, hide);
      console.log("hitApi ", hitApi);

      if (hitApi.status === 200) {
        const rs = hitApi.data.response;
        ex.promoId = rs[idx].promoId;
        ex.discount = true;
        ex.priceDiscount = rs[idx].promoPrice;

        Toastify({
          text: "Selamat, Anda mendapatkan Promo.",
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "#0e2c88",
          stopOnFocus: true,
        }).showToast();
      }
    },
    async buyPaket() {
      const ex = this.exDataUA;
      const slug = `${ex.packageId}-${ex.paymentId}-${
        ex.promoId === "" ? "0" : ex.promoId
      }`;

      await gpf.hideModal("#modal-pilih-paket");
      this.$router.push(`upgrade-paket/invoice/` + slug);
    },
  },
});
